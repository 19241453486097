import * as d3 from 'd3';
import cx from 'classnames';
import i18next from 'i18next';
import { capitalize } from 'lodash';

import resetIconSvg from '../../../../assets/reset.svg';
import { GenericParam } from '../../../interfaces/commons';
import { getCurrentTheme } from '../../../../helpers/theme';
import { applyThousandsSeparator } from '../../../../utils/strings';
import { renderTickFormat, renderTickLeftFormat } from '../ShadedPlot/helpers';
import { ReferenceCurves } from '../../../../pages/Reports/ActiveTanksDashboard/interfaces';
import { roundLength, roundWeight, stockingPhaseTypes, THEME } from '../../../../config/commons';
import { getScaleActiveTanks, getScaleYValues, rescaleCircleSize, uniformityRange } from '../../../../pages/Reports/ActiveTanksDashboard/helpers';

import { Point, Tank } from './interfaces';
import styles from './ActiveTankChartD3.module.scss';
import { getDataHigh, getDataLow, getMaxY, getMinY, getNumberTicks, getScaleYLimits, getMarginArea } from './helpers';

let zoomDone = false;
let numbersTicks: number;
let idleTimeout: NodeJS.Timeout | null;

interface Props {
  container: HTMLDivElement | null;
  height: number;
  parameter: string;
  phaseType: string;
  width: number;
  firstStage: number;
  lastStage: number;
  activeTanks: Tank[];
  referenceCurve?: ReferenceCurves;
  colors: string[];
}

let scaleActiveTanks: Tank[] = [];
let isLightTheme = true;
const tooltipWidth = 200;

const TIME_TRANSITION = 300;

class ActiveTankChartD3 {
  container: HTMLDivElement | null;
  svg: d3.Selection<SVGSVGElement, Tank, null, undefined>;
  groupMain: d3.Selection<SVGGElement, Tank, null, undefined>;

  x: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  y: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never> = d3.scaleLinear();

  margin = { top: 16, right: 24, bottom: 24, left: 60 };
  tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));
  tooltipOption: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));

  parameter: string;
  phaseType: string;
  width: number;
  height: number;
  xAxis: d3.Selection<SVGGElement, Tank, null, undefined> = d3.select<SVGGElement, Tank>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  yAxis: d3.Selection<SVGGElement, Tank, null, undefined> = d3.select<SVGGElement, Tank>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  activeTanks: Tank[] = [];
  firstStage: number;
  lastStage: number;
  referenceCurve?: ReferenceCurves;
  colors: string[] = [];

  clip: d3.Selection<d3.BaseType, Tank, null, undefined> = d3.select<d3.BaseType, Tank>(document.createElementNS('http://www.w3.org/2000/svg', 'defs'));;
  brush: d3.BrushBehavior<Tank> = d3.brush();
  gClipPath: d3.Selection<SVGGElement, Tank, null, undefined> = d3.select<SVGGElement, Tank>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  // eslint-disable-next-line
  constructor(props: Props) {
    const { container, height, width, activeTanks, parameter, phaseType, firstStage, lastStage, referenceCurve, colors } = props;

    this.container = container;
    this.parameter = parameter;
    this.phaseType = phaseType;
    this.activeTanks = activeTanks;
    this.firstStage = firstStage;
    this.lastStage = lastStage;
    this.referenceCurve = referenceCurve;
    this.colors = colors;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    d3.select(container).select('svg').remove();

    const theme = getCurrentTheme();
    isLightTheme = theme === THEME.LIGHT;

    this.svg = d3.select<HTMLDivElement | null, Tank>(container)
      .append('svg')
      .attr('id', '')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom);

    this.groupMain = this.svg
      .append('g')
      .attr('id', 'content')
      .attr('position', 'relative')
      .attr('transform', `translate( ${this.margin.left}, ${this.margin.top} )`);

    this.createBrushElement();

    scaleActiveTanks = getScaleActiveTanks({ parameter, phaseType, activeTanks });

    this.createTooltip();
    this.renderTooltipOption();
    this.buildXAxis();
    this.buildYAxis();

    this.drawXAxis();
    this.drawYAxis();

    this.createGClipPathElement();
    this.renderCircles();
    this.renderLines();
    this.renderTriangle();
  }

  createTooltip = () => {
    const { container } = this;

    this.tooltip = d3.select(container)
      .append('div')
      .style('display', 'none')
      .attr('class', styles.tooltip);
  };

  renderTooltipOption = () => {
    d3.select(this.container).select('#tooltipOption').remove();

    this.tooltipOption = d3.select(this.container)
      .append('div')
      .attr('id', 'tooltipOption')
      .attr('class', styles.tooltipOption);
  };

  createBrushElement = () => {
    const { height, width } = this;

    this.brush = d3.brush<Tank>()
      .extent([[0, 0], [width, height]])
      .on('end', (event) => this.onBrush(event));
  };

  createGClipPathElement = () => {
    const { groupMain, height, width } = this;

    this.clip = groupMain.append('defs')
      .attr('id', 'defs')
      .append('svg:clipPath')
      .attr('id', 'clip')
      .append('svg:rect')
      .attr('width', width)
      .attr('height', height)
      .attr('x', 0)
      .attr('y', 0);

    this.gClipPath = groupMain.append('g')
      .attr('id', 'gClipPath')
      .attr('clip-path', 'url(#clip)');

    this.renderResetBrush();
  };

  onBrush = (event: GenericParam) => {
    if (!event.sourceEvent || !event.selection) {
      const { firstStage, lastStage } = this;
      const { maxYValue, minYValue } = this.getYValues();
      if (!idleTimeout) {
        return idleTimeout = setTimeout(() => {
          idleTimeout = null;
        }, 350);
      }

      this.resetBrush({ x0: firstStage, y0: minYValue, x1: lastStage, y1: maxYValue });
      return;
    }

    const [[x0, y0], [x1, y1]] = event.selection;
    this.applyBrush({ x0, y0, x1, y1 });
  }

  applyBrush = (props: { x0: number; x1: number; y0: number; y1: number; }) => {
    const { x0, x1, y0, y1 } = props;

    zoomDone = true;
    this.x.domain([x0, x1].map(this.x.invert));
    this.y.domain([y1, y0].map(this.y.invert));

    // eslint-disable-next-line
    this.gClipPath.select('.brush').call(this.brush.move as any, null);

    this.refreshBrush();
  }

  resetBrush = (props: { x0: number; x1: number; y0: number; y1: number; }) => {
    const { x0, x1, y0, y1 } = props;

    zoomDone = false;
    this.x.domain([x0, x1]);
    this.y.domain([y0, y1]);

    this.refreshBrush();
    this.hideTooltipOption();
  }

  refreshBrush = () => {
    const { container, activeTanks, parameter, phaseType, height } = this;

    const yValues = getScaleYValues({ parameter, phaseType, activeTanks });

    const axisBottom = d3.axisBottom(this.x)
      .tickFormat(renderTickFormat)
      .tickSize(-height)
      .ticks(numbersTicks)
      .tickPadding(10);

    const axisLeft = d3.axisLeft(this.y)
      .tickFormat((d) => renderTickLeftFormat({ format: d, phaseType, parameter }) as string)
      .ticks(10)
      .tickSize(0)
      .tickPadding(15);

    this.xAxis
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisBottom);

    this.yAxis
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisLeft);

    this.gClipPath
      .selectAll('circle')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('cx', (d: any) => this.x(d.analysis.stage))
      // eslint-disable-next-line
      .attr('cy', (d: any, index) => this.y(yValues[index]));

    const { pointsLow, pointsHigh } = this.generateLinePoints();

    const line = d3.line<Point>()
      .x((d) => this.x(d.x))
      .y((d) => this.y(d.y))
      .curve(d3.curveBasis);

    d3.select(container).selectAll('.lines').remove();

    this.gClipPath.append('path')
      .datum(pointsLow)
      .attr('class', cx('lines', styles.lines, styles.lowLine))
      .attr('d', line);

    this.gClipPath.append('path')
      .datum(pointsHigh)
      .attr('class', cx('lines', styles.lines, styles.highLine))
      .attr('d', line);

    this.renderResetBrush();
  }

  hideTooltipOption = () => {
    this.tooltipOption.transition()
      .duration(TIME_TRANSITION)
      .style('opacity', 0);
  };

  showTooltipOption = (marginRight: number) => {
    this.tooltipOption.transition()
      .duration(TIME_TRANSITION)
      .style('opacity', 1);

    this.tooltipOption.html(i18next.t('shadedplot.reset'))
      .style('right', (marginRight + 28) + 'px')
      .style('top', '-8px');
  };

  renderResetBrush = () => {
    const { margin, width, svg } = this;
    const marginLeft = width + margin.left - 8;

    svg.select('#resetBrushButton').remove();

    if (!zoomDone) {
      return;
    }

    svg
      .append('image')
      .attr('id', 'resetBrushButton')
      .attr('x', marginLeft)
      .attr('y', 0)
      .attr('xlink:href', resetIconSvg)
      .attr('width', 16)
      .attr('height', 16)
      .attr('cursor', 'pointer')
      .attr('filter', 'url(#background-color-filter)')
      .on('mouseover', () => this.showTooltipOption(margin.right))
      .on('mouseout', this.hideTooltipOption)
      .on('click', () => {
        const { firstStage, lastStage } = this;
        const { maxYValue, minYValue } = this.getYValues();
        this.resetBrush({ x0: firstStage, y0: minYValue, x1: lastStage, y1: maxYValue });
      });

    svg
      .append('defs')
      .attr('id', 'defsBackgroundIcon')
      .append('filter')
      .attr('id', 'background-color-filter')
      .append('feFlood')
      .attr('flood-color', '#959595')
      .attr('result', 'backgroundColor');

    svg.select('#background-color-filter').append('feComposite')
      .attr('id', 'feCompositeBackgroundIcon')
      .attr('in', 'backgroundColor')
      .attr('in2', 'SourceGraphic')
      .attr('operator', 'atop');
  };

  buildXAxis () {
    const { width, firstStage, lastStage } = this;

    this.x = d3.scaleLinear()
      .domain([firstStage, lastStage])
      .range([0, width]);
  }

  getYValues = () => {
    const { parameter, phaseType, activeTanks, firstStage, lastStage } = this;
    const margin = getMarginArea(parameter);

    const { pointsLow, pointsHigh } = this.generateLinePoints();

    let minYValue = getMinY({ parameter, phaseType, activeTanks, firstStage, lastStage, points: pointsLow });
    let maxYValue = getMaxY({ parameter, phaseType, activeTanks, firstStage, lastStage, points: pointsHigh });

    minYValue = minYValue - (minYValue * margin) < 0 ? 0 : minYValue - (minYValue * margin);
    maxYValue = maxYValue + (maxYValue * margin);

    return {
      maxYValue,
      minYValue,
    };
  };

  buildYAxis () {
    const { height } = this;
    const { maxYValue, minYValue } = this.getYValues();

    this.y = d3.scaleLinear()
      .domain([minYValue, maxYValue])
      .range([height, 0]);
  }

  drawXAxis () {
    const { height, groupMain, phaseType, firstStage, lastStage } = this;

    numbersTicks = getNumberTicks({ phaseType, firstStage, lastStage });

    const axisBottom = d3.axisBottom(this.x)
      .tickFormat(renderTickFormat)
      .tickSize(-height)
      .ticks(numbersTicks)
      .tickPadding(10);

    this.xAxis = groupMain.append('g')
      .attr('id', 'axisX')
      .attr('class', cx(styles.axisX, isLightTheme ? styles.axisLight : styles.axisDark))
      .attr('transform', `translate(0, ${this.height})`)
      .call(axisBottom);
  }

  drawYAxis () {
    const { phaseType, parameter, groupMain } = this;

    const axis = d3.axisLeft(this.y)
      .tickFormat((d) => renderTickLeftFormat({ format: d, phaseType, parameter }) as string)
      .ticks(10)
      .tickSize(0)
      .tickPadding(15);

    this.yAxis = groupMain.append('g')
      .attr('id', 'axisY')
      .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .call(axis);
  }

  updateXAxis = () => {
    const { height, phaseType, firstStage, lastStage } = this;

    numbersTicks = getNumberTicks({ phaseType, firstStage, lastStage });

    const axisBottom = d3.axisBottom(this.x)
      .tickFormat(renderTickFormat)
      .tickSize(-height)
      .ticks(numbersTicks)
      .tickPadding(10);

    this.xAxis
      .attr('fill', 'transparent')
      .attr('transform', `translate(0, ${this.height})`)
      .attr('class', cx(styles.axisX, isLightTheme ? styles.axisLight : styles.axisDark))
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisBottom);
  };

  updateYAxis = () => {
    const { parameter, phaseType } = this;

    const axisLeft = d3.axisLeft(this.y)
      .tickFormat((d) => renderTickLeftFormat({ format: d, phaseType, parameter }) as string)
      .ticks(10)
      .tickSize(0)
      .tickPadding(15);

    this.yAxis
      .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisLeft);
  };

  renderTriangle = () => {
    const { container, margin, height } = this;

    const triangleMargins = {
      left: margin.left - 5,
      top: -(height + margin.top + margin.bottom + 11),
    };

    d3.select(container).selectAll('.triangle').remove();

    d3.select(container)
      .append('div')
      .attr('class', cx('triangle', styles.triangle))
      .style('transform', `translate(${triangleMargins.left}px, ${triangleMargins.top}px)`);
  };

  renderCircles = () => {
    const { activeTanks, parameter, phaseType, colors, gClipPath, brush } = this;

    const yValues = getScaleYValues({ parameter, phaseType, activeTanks });
    const uniformityWeight = scaleActiveTanks.map((tank) => tank.analysis.uniformityWeight);

    gClipPath
      .append('g')
      .attr('class', 'brush')
      .call(brush);

    gClipPath
      .selectAll('circle')
      .data(scaleActiveTanks)
      .enter()
      .append('circle')
      .attr('class', cx('circle', styles.circle, isLightTheme ? styles.circleLight : styles.circleDark))
      .attr('cx', (d) => this.x(d.analysis.stage))
      .attr('cy', (d, index) => this.y(yValues[index]))
      .attr('r', (d, index) => rescaleCircleSize({ data: uniformityWeight, range: uniformityRange, axisValue: uniformityWeight[index] }))
      .style('fill', (d, index) => colors[index])
      .on('mouseover', (event, tank: Tank) => this.onMouseover({ event, tank, uniformityWeight }))
      .on('mousemove', (event, tank: Tank) => this.onMousemove({ event, tank, uniformityWeight }))
      .on('mouseleave', (event, tank: Tank) => this.onMouseleave({ event, tank, uniformityWeight }))
      .on('click', (event, tank: Tank) => this.onClickCircle(tank));
  };

  onMouseover = (props: { event: GenericParam; tank: Tank; uniformityWeight: number[] }) => {
    const { event, tank, uniformityWeight } = props;

    this.tooltip
      .style('display', 'flex');

    d3.select(event.currentTarget)
      .attr('r', rescaleCircleSize({ data: uniformityWeight, range: uniformityRange, axisValue: tank.analysis.uniformityWeight }) * 1.10)
      .style('stroke-width', 1.5);
  };

  generateContentTooltip = (tank: Tank) => {
    if (this.phaseType === stockingPhaseTypes.LARVAE) {
      return `
        <h3>${tank.name}</h3>
        <ul>
          <li>${i18next.t('analysis.inputData.stage')}: <strong> ${tank.analysis.stage} </strong> </li>
          <li>${capitalize(i18next.t('stockings.pdf.typeParameter.uniformity').toLowerCase())}: <strong> ${tank.analysis.uniformityWeight} % </strong> </li>
          <li>${i18next.t('analysis.resultData.larvaePerGram')}: <strong> ${tank.analysis.larvaePerGram} </strong> </li>
          <li>${i18next.t('analysis.resultData.averageWeight')}: <strong> ${roundWeight({ value: tank.analysis.averageWeight })} </strong> </li>
          <li>${i18next.t('analysis.resultData.averageLength')}: <strong> ${roundLength({ value: tank.analysis.averageLength })} </strong> </li>
          <li>${i18next.t('production.stockingInfoModal.stockingDensity')}: <strong>${applyThousandsSeparator(tank.stockingDensity)} ${tank.densityUnit}</strong></li>
          <li>${i18next.t('production.stockingInfoModal.currentDensity')}: <strong>${applyThousandsSeparator(tank.currentDensity)} ${tank.densityUnit}</strong></li>
        </ul>
      `;
    }

    const conditionFactor = tank.analysis.animalsAboveConditionFactor ? (
      `<li>${i18next.t('activeTanks.factorK')}: <strong>${tank.analysis.animalsAboveConditionFactor} %</strong></li>`
    ) : '';

    return `
        <h3>${tank.name}</h3>
        <ul>
          <li>${i18next.t('analysis.inputData.days')}: <strong> ${tank.analysis.stage} </strong> </li>
          <li>${capitalize(i18next.t('stockings.pdf.typeParameter.uniformity').toLowerCase())}: <strong> ${tank.analysis.uniformityWeight} % </strong> </li>
          <li>${i18next.t('analysis.resultData.averageWeight')}: <strong> ${roundWeight({ value: tank.analysis.averageWeight })} </strong> </li>
          <li>${i18next.t('analysis.resultData.averageLength')}: <strong> ${roundLength({ value: tank.analysis.averageLength })} </strong> </li>
          <li>${i18next.t('production.stockingInfoModal.stockingDensity')}: <strong>${applyThousandsSeparator(tank.stockingDensity)} ${tank.densityUnit}</strong></li>
          <li>${i18next.t('production.stockingInfoModal.currentDensity')}: <strong>${applyThousandsSeparator(tank.currentDensity)} ${tank.densityUnit}</strong></li>
          ${conditionFactor}
        </ul>
      `;
  };

  onMousemove = (props: { event: GenericParam; tank: Tank; uniformityWeight: number[] }) => {
    const { event, tank, uniformityWeight } = props;

    const svgRect = this.svg.node()?.getBoundingClientRect();

    const radio = rescaleCircleSize({ data: uniformityWeight, range: uniformityRange, axisValue: tank.analysis.uniformityWeight });
    const stage = this.x(tank.analysis.stage);

    let mouseX = event.clientX - (svgRect?.left || 0) + radio;
    const mouseY = event.clientY - (svgRect?.top || 0);
    mouseX = stage + tooltipWidth < this.width ? mouseX : mouseX - tooltipWidth;

    this.tooltip
      .html(this.generateContentTooltip(tank))
      .style('left', (mouseX) + 'px')
      .style('top', (mouseY + 'px'));
  };

  onMouseleave = (props: { event: GenericParam; tank: Tank; uniformityWeight: number[] }) => {
    const { event, tank, uniformityWeight } = props;

    this.tooltip
      .style('display', 'none');

    d3.select(event.currentTarget)
      .attr('r', rescaleCircleSize({ data: uniformityWeight, range: uniformityRange, axisValue: tank.analysis.uniformityWeight }))
      .style('stroke-width', 1);
  };

  onClickCircle = (tank: Tank) => {
    const url = `/production/analysis/${tank.analysis.analysisId}`;
    window.open(url, '_blank');
  };

  generateLinePoints = () => {
    const { referenceCurve, parameter, phaseType, firstStage, lastStage } = this;

    if (!referenceCurve?._id) {
      return {
        pointsLow: [],
        pointsHigh: [],
      };
    }

    const { x: xLow, y: yLow } = getDataLow(referenceCurve.values);
    const { x: xHigh, y: yHigh } = getDataHigh(referenceCurve.values);

    const scaleYLow = getScaleYLimits({ parameter, phaseType, yValues: yLow });
    const scaleYHigh = getScaleYLimits({ parameter, phaseType, yValues: yHigh });

    const pointsLow: Point[] = xLow.map((x, index) => ({ x, y: scaleYLow[index] })).filter((point) => point.x >= firstStage && point.x <= lastStage);
    const pointsHigh: Point[] = xHigh.map((x, index) => ({ x, y: scaleYHigh[index] })).filter((point) => point.x >= firstStage && point.x <= lastStage);

    return {
      pointsLow,
      pointsHigh,
    };
  };

  renderLines = () => {
    const { referenceCurve, gClipPath } = this;

    if (!referenceCurve?._id) {
      return;
    }

    const { pointsLow, pointsHigh } = this.generateLinePoints();

    const line = d3.line<Point>()
      .x((d) => this.x(d.x))
      .y((d) => this.y(d.y))
      .curve(d3.curveBasis);

    gClipPath.append('path')
      .datum(pointsLow)
      .attr('class', cx('lines', styles.lines, styles.lowLine))
      .attr('d', line);

    gClipPath.append('path')
      .datum(pointsHigh)
      .attr('class', cx('lines', styles.lines, styles.highLine))
      .attr('d', line);
  }

  refreshChart = (props: { parameter: string; width: number; firstStage: number; lastStage: number; activeTanks: Tank[]; referenceCurve?: ReferenceCurves; colors: string[] }) => {
    const { activeTanks, parameter, width, firstStage, lastStage, referenceCurve, colors } = props;
    const { container, phaseType } = this;

    const theme = getCurrentTheme();
    isLightTheme = theme === THEME.LIGHT;

    this.parameter = parameter;
    this.width = width - this.margin.left - this.margin.right;
    this.firstStage = firstStage;
    this.lastStage = lastStage;
    this.activeTanks = activeTanks;
    this.referenceCurve = referenceCurve;
    this.colors = colors;

    scaleActiveTanks = getScaleActiveTanks({ parameter, phaseType, activeTanks });

    d3.select(container).selectAll('.circle').remove();
    d3.select(container).selectAll('.brush').remove();
    d3.select(container).selectAll('.lines').remove();
    d3.select(container).select('#defs').remove();
    d3.select(container).select('#gClipPath').remove();

    this.createBrushElement();

    this.buildXAxis();
    this.buildYAxis();

    this.updateXAxis();
    this.updateYAxis();

    this.createGClipPathElement();
    this.renderCircles();
    this.renderLines();
  }
}

export default ActiveTankChartD3;
